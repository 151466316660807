import React, { Component, Fragment } from 'react';
import config from '../config';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider, Form } from "semantic-ui-react";
import Dialog from '../components/UI/Dialog';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { displayErrors } from '../helper';

class CommissionSchemes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isUpdateDialog: false,
            isDeleteDialog: false,
            loading: false,
            id: '',
            name: '',
            fixedValue: '',
            percentageValue: ''
        }

        this.callbackFunction = this.callbackFunction.bind(this);
        this.createCommissionScheme = this.createCommissionScheme.bind(this);
        this.createNewCommissionSchemeHandler = this.createNewCommissionSchemeHandler.bind(this);
        this.updateCommissionSchemeHandler = this.updateCommissionSchemeHandler.bind(this);
        this.deleteCommissionSchemeHandler = this.deleteCommissionSchemeHandler.bind(this);
    };

    
    callbackFunction(childData, identifier) {
        this.setState({ id: childData.id });
        this.setState({ name: childData.name });
        this.setState({ fixedValue: childData.fixedValue });
        this.setState({ percentageValue: childData.percentageValue });

        if (identifier === 'update') {
            this.setState({ isUpdateDialog: true });
        } else if (identifier === 'delete') {
            this.setState({ isDeleteDialog: true });
        }
        
    };

    createCommissionScheme() {
        this.setState({ open: true });
    };

    createNewCommissionSchemeHandler(values) {
        const { name, fixedValue, percentageValue } = values;
        const fixedValueToSend = fixedValue === '' ? null : parseFloat(fixedValue);
        const percentageValueToSend = percentageValue === '' ? null : parseFloat(percentageValue);

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/commissionschema/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "name": name,
                "fixedValue": fixedValueToSend,
                "percentageValue": percentageValueToSend
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ open: false });
                this.setState({ name: '' });
                this.setState({ fixedValue: '' });
                this.setState({ percentageValue: '' });
                toast.success('Commission Schema created successfully!');
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        });
    };


    updateCommissionSchemeHandler(values) { 
        const { id } = this.state;
        const { name, fixedValue, percentageValue } = values;
        const parsedFixedValue = fixedValue === '' ? null : parseFloat(fixedValue);
        const parsedPercentageValue = percentageValue === '' ? null : parseFloat(percentageValue);


        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/commissionschema/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": id,
                "name": name,
                "fixedValue": parsedFixedValue,
                "percentageValue": parsedPercentageValue
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ id: '' });
                this.setState({ name: '' });
                this.setState({ fixedValue: 0 });
                this.setState({ percentageValue: 0 });
                toast.success('Commission Schema updated successfully!');
                this.setState({ isUpdateDialog: false });
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        })
    };
    
    deleteCommissionSchemeHandler() {
        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/commissionschema/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": this.state.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ isDeleteDialog: false });
                this.setState({ id: '' });
                this.setState({ name: '' });
                this.setState({ fixedValue: 0 });
                this.setState({ percentageValue: 0 });
                toast.success('Commission Schema deleted successfully!');
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    };

    render() {
        const { name, fixedValue, percentageValue, loading, open, isUpdateDialog, isDeleteDialog } = this.state;

        return (
            <Fragment>
                <HeaderContent onClickHandler={this.createCommissionScheme} buttonTitle={'Create New Commission Schema'} showButton={true} />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} parentCallback={this.callbackFunction} />

                <Formik
                    initialValues={{ name: name, fixedValue: fixedValue, percentageValue: percentageValue }}
                    onSubmit={(values, { resetForm }) => {
                        this.createNewCommissionSchemeHandler(values);
                        resetForm();
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('This field is required.'),
                        fixedValue: Yup.number().when('percentageValue', {
                            is: (percentageValue) => percentageValue === undefined || percentageValue === null || percentageValue === '',
                            then: Yup.number()
                                .moreThan(-1, 'Fixed Value cannot be negative')
                                .required('Either Fixed Value or Percentage Value is required.')
                                .max(10000000000, 'Fixed Value must be at most 10 digits.'),
                            otherwise: Yup.number()
                                .moreThan(-1, 'Fixed Value cannot be negative')
                                .max(10000000000, 'Fixed Value must be at most 10 digits.')
                        }),
                        percentageValue: Yup.number()
                            .moreThan(-1, 'Percentage Value cannot be negative')
                            .max(102, 'Percentage Value must be at most 100.'),
                    })}   
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit,resetForm, }) => (
                        <Dialog
                            open={open}
                            onCancel={() => {
                                resetForm();
                                this.setState({ open: false })
                            }}
                            onConfirm={handleSubmit}
                            title={'Create New Commission Schema'}
                            confirmText={'Create Commission Schema'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Field>
                                    <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.name && touched.name ? (
                                        <span className='formik-error-msg'>{errors.name}</span>
                                    ) : null}
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input fluid label="Fixed Value" type='number' name='fixedValue' placeholder='Fixed Value' value={values.fixedValue} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.fixedValue && touched.fixedValue ? (
                                        <span className='formik-error-msg'>{errors.fixedValue}</span>
                                    ) : null}
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input fluid label="Percentage Value" type='number' name='percentageValue' placeholder='Percentage Value' value={values.percentageValue} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.percentageValue && touched.percentageValue ? (
                                        <span className='formik-error-msg'>{errors.percentageValue}</span>
                                    ) : null}
                                </Form.Field>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Formik
                    enableReinitialize
                    initialValues={{ name: name, fixedValue: fixedValue, percentageValue: percentageValue  }}
                    onSubmit={values => {
                        this.updateCommissionSchemeHandler(values);
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('This field is required.'),
                        fixedValue: Yup.number().when('percentageValue', {
                            is: (percentageValue) => percentageValue === undefined || percentageValue === null || percentageValue === '',
                            then: Yup.number()
                                .moreThan(-1, 'Fixed Value cannot be negative')
                                .required('Either Fixed Value or Percentage Value is required.')
                                .max(10000000000, 'Fixed Value must be at most 10 digits.')
                                .nullable(),
                            otherwise: Yup.number()
                                .moreThan(-1, 'Fixed Value cannot be negative')
                                .max(10000000000, 'Fixed Value must be at most 10 digits.')
                                .nullable()
                        }),
                        percentageValue: Yup.number()
                            .moreThan(-1, 'Percentage Value cannot be negative')
                            .max(102, 'Percentage Value must be at most 100.')
                            .nullable(),
                    })}  
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit,resetForm }) => (
                        <Dialog
                            open={isUpdateDialog}
                            onCancel={() => {
                                this.setState({ isUpdateDialog: false })
                                resetForm();
                            }}
                            onConfirm={handleSubmit}
                            title={'Update Commission Schema'}
                            confirmText={'Save'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Field>
                                    <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.fixedValue && touched.name ? (
                                        <span className='formik-error-msg'>{errors.name}</span>
                                    ) : null}
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input fluid label="Fixed Value" type='number' name='fixedValue' placeholder='Fixed Value' value={values.fixedValue} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.fixedValue && touched.fixedValue ? (
                                        <span className='formik-error-msg'>{errors.fixedValue}</span>
                                    ) : null}
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input fluid label="Percentage Value" type='number' name='percentageValue' placeholder='Percentage Value' value={values.percentageValue} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.percentageValue && touched.percentageValue ? (
                                        <span className='formik-error-msg'>{errors.percentageValue}</span>
                                    ) : null}
                                </Form.Field>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Dialog
                    open={isDeleteDialog}
                    onCancel={() => {
                        this.setState({ isDeleteDialog: false });
                        this.setState({ id: '' });
                        this.setState({ name: '' });
                    }}
                    onConfirm={this.deleteCommissionSchemeHandler}
                    title={'Delete Commission Schema'}
                    confirmText={'Delete Commission Schema'}
                    loading={loading}
                ><p>Are you sure you want to delete this commission schema?</p></Dialog>

                <ToastContainer />
            </Fragment >
        );
    }
};

export default CommissionSchemes;